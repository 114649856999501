import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Pagination from "rc-pagination"

// Components
import Icon from "./icon"

export default ({ currentPage, setCurrentPage, slug, numberOfPages }) => {
  const link = page => {
    if (page === 1 || page === 0) {
      return slug ? `/${slug}` : `/`
    }

    return slug ? `/${slug}/page/${page}` : `/page/${page}`
  }

  const handleChange = current => {
    setCurrentPage(current)
  }

  return (
    <Pagination
      current={currentPage + 1}
      total={numberOfPages * 5}
      pageSize={5}
      showQuickJumper={false}
      showPrevNextJumpers
      showSizeChanger={false}
      itemRender={(current, type, element) => {
        if (type === "page") {
          return (
            <Button
              aria-label={`Go to Page ${current}`}
              to={link(current)}
              className={current === currentPage + 1 && `active`}
            >
              {current}
            </Button>
          )
        }

        if (type === "prev") {
          return (
            <Button aria-label={`Go to Previous Page`} to={link(current)}>
              <Icon />
            </Button>
          )
        }

        if (type === "next") {
          return (
            <Button aria-label={`Go to Next Page`} to={link(current)}>
              <Icon invert />
            </Button>
          )
        }

        return element
      }}
      onChange={handleChange}
      prevIcon={Icon}
      showLessItems={true}
    />
  )
}

// Styled Components
const Button = styled(Link)`
  width: 100%;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  &.active,
  &:hover {
    background-color: ${props => props.theme.color.blue["50"]};
    color: ${props => props.theme.color.blue["400"]};
  }
`
